import React from "react"
import { StaticQuery, graphql } from "gatsby"

import css from "./Image.module.scss"

type Props = {
  filename: string
  title?: string
  linkUrl?: string
  className?: string;
  linkClassName?: string;
}

const Image: React.FC<Props> = ({
  filename,
  className = "",
  linkClassName = "",
  title = "",
  linkUrl = "",
}) => {
  const filePath = [filename]
  const render = data => {
    const url = [data.site.siteMetadata.baseAssetUrl.replace(/\/$/, "")]
      .concat(filePath)
      .join("/")
    const img = <img className={[css.image, className || ""].join(" ")} src={url} alt={title} />
    if (linkUrl.length) {
      return (
        <a
          className={[css.image__link, linkClassName || ""].join(" ")}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {img}
        </a>
      )
    }
    return img
  }

  return (
    <StaticQuery
      query={graphql`
        query ImageQuery {
          site {
            siteMetadata {
              baseAssetUrl
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default Image
