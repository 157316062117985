import React from "react"
import { Link, graphql } from "gatsby"

import "../lib/polyfill.ts"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import Image from "../components/Image"
import Button from "../components/Button"
import SVGIcon from "../components/SVGIcon"
import ClickToCopy from "../components/ClickToCopy";
import ClosestAlpha from "../components/ClosestAlpha"
import withLocation from "../../lib/HOC/withLocation"

import css from "./Card.module.scss"

const imgUrlToCardId = (img) =>
  parseInt(img.split('/').pop().replace('.png', ''), 10);

const Card = ({ data, location, search }) => {
  const fields = data.cardsCsv.fields || {}
  const prevCard = data.cardsCsv.prevCard || null
  const nextCard = data.cardsCsv.nextCard || null
  const cardImageRange = [
    ...Array(data.cardsCsv.lastImageId - data.cardsCsv.firstImageId + 1).keys(),
  ]
    .map(key => data.cardsCsv.firstImageId + key)
    .map(cardId => {
      const full = cardId.toString().padStart(7, "0")
      return `cards/${full.substring(0, 3)}/${full}.png`
    })
  const initialCardId = parseInt(search.startCard || 0, 10);
  const searchQuery = search.query && search.query.length ? search.query : ""
  const querySuffix = searchQuery.length > 0 ? `?query=${searchQuery}` : ""

  const cardIds = cardImageRange.map(imgUrlToCardId);
  const initialIndex = cardIds.indexOf(initialCardId);

  const [imageIndex, setImageIndex] = React.useState(initialIndex > -1 ? initialIndex : 0);

  const { href = null } = location;
  const currentUrl = href && `${href.replace(/\?.*$/, '')}?startCard=${imgUrlToCardId(cardImageRange[imageIndex])}`;

  return (
    <Layout>
      <HeadMeta pageTitle={`${fields.CT_Term}`} />
      <PageHeader>{fields.CT_Term}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Scanned card catalogue", href: "/" },
          { name: fields.CT_Term, href: `/${fields.slug}` },
        ]}
      />
      {searchQuery.length > 0 && (
        <p>
          You searched for: <strong>{searchQuery}</strong>
        </p>
      )}

      <Image
        filename={cardImageRange[imageIndex]}
        title={fields.CT_Term}
        className={css.image}
      />

      <nav className={[css.prevNextNav, css.prevNextCardNav].join(' ')}>
        {imageIndex > 0 && (
          <Button
            onClick={() => {
              if (imageIndex > 0) {
                setImageIndex(imageIndex - 1)
              }
            }}
            className={[css.prev, css.link].join(" ")}
          >
            <SVGIcon name="left" className={css.prevIcon} size="sm" />
            <span className={css.linkAction}>Previous card</span>
          </Button>
        )}
        {imageIndex <= 0 && <span className={css.prev} />}

        <div className={css.navIndicator}>
          <span className={css.navIndicatorText}>Card {imageIndex + 1} out of {cardImageRange.length}</span>
          <ClickToCopy copyText={currentUrl}>
            Click to copy URL
          </ClickToCopy>
        </div>

        {imageIndex < cardImageRange.length - 1 && (
          <Button
            onClick={() => {
              if (imageIndex < cardImageRange.length - 1) {
                setImageIndex(imageIndex + 1)
              }
            }}
            className={[css.next, css.link].join(" ")}
          >
            <SVGIcon name="right" className={css.nextIcon} size="sm" />
            <span className={css.linkAction}>Next card</span>
          </Button>
        )}
        {imageIndex >= cardImageRange.length - 1 && (
          <span className={css.prev} />
        )}
      </nav>

      <nav className={[css.prevNextNav, css.prevNextCardSetNav].join(" ")}>
        {prevCard && (
          <Link
            to={`/${prevCard.fields.slug}${querySuffix}`}
            className={[css.prev, css.link].join(" ")}
          >
            <SVGIcon name="left" className={css.prevIcon} size="lg" />
            <span className={css.linkAction}>Previous</span>
            <span className={css.linkLabel}>{prevCard.fields.CT_Term}</span>
          </Link>
        )}
        {nextCard && (
          <Link
            to={`/${nextCard.fields.slug}${querySuffix}`}
            className={[css.next, css.link].join(" ")}
          >
            <SVGIcon name="right" className={css.nextIcon} size="lg" />
            <span className={css.linkAction}>Next</span>
            <span className={css.linkLabel}>{nextCard.fields.CT_Term}</span>
          </Link>
        )}
      </nav>

      <nav className={[css.prevNextNav].join(" ")}>
        <div className={css.prev}>
          <ClosestAlpha
            showTitle=""
            showQuery={searchQuery.length > 0}
            phrase={searchQuery || fields.CT_Term}
            max={10}
            skip={2}
            showPrev={true}
            className={css.listPlain}
          />
        </div>
        <div className={css.next}>
          <ClosestAlpha
            showTitle=""
            showQuery={searchQuery.length > 0}
            phrase={searchQuery || fields.CT_Term}
            max={10}
            skip={1}
            showNext={true}
            className={css.listPlain}
          />
        </div>
      </nav>
    </Layout>
  )
}

export default withLocation(Card)

export const query = graphql`
  query CardPageQuery($cardId: String!) {
    cardsCsv(fields: { CT_ID: { eq: $cardId } }) {
      fields {
        CT_Term
        CT_Card
        slug
      }
      prevCard {
        fields {
          CT_Term
          slug
        }
      }
      nextCard {
        fields {
          CT_Term
          slug
        }
      }
      firstImageId
      lastImageId
    }
  }
`
