import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import levenshtein from "js-levenshtein"
import card from "../../templates/card"

type Props = {
  phrase?: string
  max?: number
  skip?: number
  showPrev?: boolean
  showNext?: boolean
  highlightExact?: boolean
  showTitle?: string
  showQuery?: boolean
  className?: string
}

const escapeRegExp = string => {
  return string.replace(/[.*+\-?^${}\(\)\|\[\]\\]/g, "\\$&")
}

const sortPrep = string => string.toLowerCase().replace(/[^a-z0-9\s\.]+/, "-")

const ClosestAlpha: React.FC<Props> = ({
  phrase = "",
  max = 5,
  skip = 0,
  showPrev = false,
  showNext = false,
  highlightExact = false,
  showTitle = "Possible matches:",
  showQuery = true,
  className,
}) => {
  if (phrase.length === 0) {
    return []
  }

  const phraseRegex = new RegExp(
    `^${escapeRegExp(phrase.toLowerCase()).substring(0, 1)}`,
    "i"
  )

  const render = ({ allCardsCsv }) => {
    const rawFound = allCardsCsv.edges
      .filter(cardEdge =>
        cardEdge.node.fields.CT_Term.toLowerCase().match(phraseRegex)
      )
      .map(cardEdge => {
        return {
          edge: cardEdge,
          str: cardEdge.node.fields.CT_Term.toLowerCase(),
          sort:
            -1 *
            phrase.localeCompare(cardEdge.node.fields.CT_Term.toLowerCase()),
          lev: levenshtein(phrase, cardEdge.node.fields.CT_Term.toLowerCase()),
        }
      })
    const found = rawFound.reduce(
      (agg, item) => {
        if (phrase.localeCompare(item.str) < 0) {
          agg.preFound.push(item)
        } else {
          agg.postFound.push(item)
        }
        return agg
      },
      { preFound: [], postFound: [] }
    )
    const querySuffix = showQuery ? `?query=${phrase}` : ""
    return (
      <>
        {showTitle.length > 0 && <p>{showTitle}</p>}
        <ul className={className}>
          {showPrev &&
            found.postFound.slice(skip, max + skip).map(cardEdge => (
              <li key={cardEdge.edge.node.fields.slug}>
                <Link to={`/${cardEdge.edge.node.fields.slug}${querySuffix}`}>
                  {cardEdge.edge.node.fields.CT_Term}
                </Link>
              </li>
            ))}
          {showNext &&
            found.preFound
              .reverse()
              .slice(skip, max + skip)
              .map(cardEdge => (
                <li key={cardEdge.edge.node.fields.slug}>
                  <Link to={`/${cardEdge.edge.node.fields.slug}${querySuffix}`}>
                    {cardEdge.edge.node.fields.CT_Term}
                  </Link>
                </li>
              ))}
        </ul>
      </>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query ClosestAlphaQuery {
          allCardsCsv(
            filter: { fields: { CT_Term: { nin: [null, "", "CT_Term"] } } }
            sort: { fields: firstImageId, order: DESC }
          ) {
            edges {
              node {
                fields {
                  CT_Term
                  slug
                }
                firstImageId
              }
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default ClosestAlpha
