import React from "react";

import Button from '../Button';
import IconButton from '../IconButton';

import css from "./ClickToCopy.module.scss"

const ClickToCopy = ({ copyText, children }) => {
  const [show, setShow] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const inputRef = React.useRef(null);
  const timeoutRef = React.useRef(null);

  const onCopy = (e) => {
    e.preventDefault();
    if (!window || !inputRef.current) {
      console.error('Could not find source field to copy from.');
    }
    inputRef.current.select();
    window.document.execCommand("copy");
    inputRef.current.blur();
    setCopied(true);
    console.log(`Copied ${inputRef.current.value}`);
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <>
      <IconButton
        className={css.toggleButton}
        iconName="share"
        iconSize="xs"
        onClick={() => setShow(!show)}
      >
        Share
      </IconButton>
      {show && <div>
        <Button className={css.button} onClick={onCopy}>
          {copied && `Copied to clipboard.`}
          {!copied && children}
        </Button>
        <br />
        <textarea
          className={css.textField}
          readOnly
          value={copyText}
          ref={inputRef}
          tabIndex={-1}
        />
      </div>}
    </>
  );
};

export default ClickToCopy;
